<template>
  <div class="landing-builder-container">
    <div v-if="selectedMode === 'design'" class="design-box">
      <div class="card">
        <div class="card-body">
          <div role="tablist" class="custom-accordion ml-4">
            <b-card v-for="section in filteredSections" :key="section.key" class="mb-1" no-body>
              <b-card-header header-tag="header" role="tab">
                <a href="javascript: void(0);" class="text-black">
                  <h5 class="m-0 font-size-14" @click="onTabClick(section.key)">
                    <i :class="{
      'uil-minus-circle text-warning':
        selectedTab !== section.key,
      'uil-plus-circle text-success':
        selectedTab === section.key,
    }" class="uil h3 icon"></i>
                    {{ section.label }}
                  </h5>
                </a>
              </b-card-header>
              <b-collapse accordion="accordion" role="tabpanel" :visible="selectedTab === section.key">
                <b-card-body class="px-0">
                  <component :is="section.component" :section="getSection(section)" :landing="landing" />
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="preview-box">
      <div class="preview-container">
        <b-tabs>
          <b-tab title="Design" active @click="selectMode('design')">
            <VoteResult ref="widget" :landing="landing" :page-mode="selectedMode" :selected-tab="selectedTab"
              @select-block="onTabClick" />
          </b-tab>
          <b-tab title="Preview" @click="selectMode('preview')">
            <div>
              <VoteResult :landing="landing" :page-mode="selectedMode" :selected-tab="selectedTab"
                @select-block="onTabClick" />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSection from './Sections/LogoSection'
import StylingSection from './Sections/StylingSection'
import ScriptsSection from './Sections/ScriptsSection'
import LegalSection from './Sections/LegalSection'
import ResultSection from './Votes/ResultSection'
import ShareSection from './Votes/ShareSection'
import VoteResult from './VoteResult'

export default {
  components: {
    VoteResult, LogoSection, StylingSection, ScriptsSection,
    ResultSection, LegalSection,
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Styling',
          component: StylingSection,
        },
        {
          key: 'logo',
          label: 'Logo',
          component: LogoSection,
        },
        {
          key: 'result',
          type: 'votes',
          label: 'Main',
          component: ResultSection,
        },
        {
          key: 'share',
          type: 'votes',
          label: 'Share',
          component: ShareSection,
        },
        {
          key: 'legalFooter',
          label: 'Legal footer',
          component: LegalSection,
        },
        {
          key: 'scripts',
          type: 'votes',
          label: 'Custom scripts',
          component: ScriptsSection,
        },
      ],
      selectedTab: null,
      selectedMode: 'design',
    }
  },

  computed: {
    filteredSections() {
      return this.sections
    }
  },

  methods: {
    selectMode(mode) {
      this.selectedMode = mode
    },

    getSection(section) {
      if (section.type === 'votes') {
        return this.landing.votes[section.key]
      }
      return this.landing.sections[section.key]
    },

    onTabClick(section) {
      this.selectedTab = section
    },

    async validateStep() {
      let stepValid = false

      if (this.landing.type === 'landing')
        stepValid = true
      else
        stepValid = await this.updateOrCreate()

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch(
          this.landing.id ? 'landing/update' : 'landing/create',
          this.landing
        )
        .then((landing) => {
          this.$emit('done', landing)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch(() => {
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
  }
}
</script>

<style lang="scss">
.landing-builder-container {
  display: flex;
  width: 100%;

  .design-box {
    width: 460px;
  }

  .preview-box {
    flex: 1 1 auto;
  }

  .nav-tabs {
    justify-content: center;

    a {
      opacity: 0.6;

      &.active {
        background: transparent;
        color: #495057;
        opacity: 1;
      }
    }
  }
}
</style>
<template>
  <div v-if="section" class="row">
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BorderColor" label="Border color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MaxWidth" label="Max. width" />
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'

export default {
  components: {
    ColorInput,
    NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>

<template>
  <div>
    <div class="editor-container">
      <div v-if="selectedMode === 'design'" class="design-box">
        <div class="card">
          <div class="card-body">
            <div role="tablist" class="custom-accordion ml-4">
              <b-card
                v-for="section in listSections"
                :key="section.key"
                class="mb-1"
                no-body
              >
                <b-card-header header-tag="header" role="tab">
                  <a href="javascript: void(0);" class="text-black">
                    <h5
                      class="m-0 font-size-14"
                      @click="onTabClick(section.key)"
                    >
                      <i
                        :class="{
                          'uil-question-circle text-warning':
                            selectedTab !== section.key,
                          'uil-check-circle text-success':
                            selectedTab === section.key,
                        }"
                        class="uil h3 icon"
                      ></i>
                      {{ section.label }}
                    </h5>
                  </a>
                </b-card-header>
                <b-collapse
                  accordion="accordion"
                  role="tabpanel"
                  :visible="selectedTab === section.key"
                >
                  <b-card-body class="px-0">
                    <component
                      :is="section.component"
                      :section="coupon.sections[section.key]"
                      :device="device"
                      :section-key="section.key"
                    />
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="preview-box">
        <div class="preview-container">
          <b-tabs>
            <b-tab
              title="Desktop"
              active
              @click="selectMode('design', 'desktop')"
            >
              <Widget
                ref="widget"
                :coupon="coupon"
                device="desktop"
                @selected-block="onTabClick"
              />
            </b-tab>
            <b-tab title="Mobile" @click="selectMode('design', 'mobile')">
              <Widget
                :coupon="coupon"
                device="mobile"
                @selected-block="onTabClick"
              />
            </b-tab>
            <b-tab title="Print" @click="selectMode('design', 'print')">
              <WidgetPrint :coupon="coupon" @selected-block="onTabClick" />
            </b-tab>
            <b-tab title="Preview" @click="selectMode('preview')">
              <div>
                <WidgetPreview :coupon="coupon" />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from '../Widget'
import WidgetPreview from '../WidgetPreview'
import WidgetPrint from '../WidgetPrint'
import TriggerButtonSection from './TriggerButtonSection'
import StylingSection from './StylingSection'
import LogoSection from './LogoSection'
import HeroImageSection from './HeroImageSection'
import HeadlineSection from './HeadlineSection'
import SubheadingSection from './SubheadingSection'
import InputsSection from './InputsSection'
import MainButtonSection from './MainButtonSection'
import MobileButtonSection from './MobileButtonSection'

export default {
  components: {
    TriggerButtonSection,
    StylingSection,
    LogoSection,
    HeroImageSection,
    HeadlineSection,
    InputsSection,
    MainButtonSection,
    Widget,
    WidgetPreview,
    MobileButtonSection,
    WidgetPrint,
  },

  props: {
    coupon: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Styling',
          component: StylingSection,
          categories: ['graphic', 'popup'],
        },
        {
          key: 'logo',
          label: 'Logo',
          component: LogoSection,
          categories: ['graphic', 'popup'],
        },
        {
          key: 'triggerButton',
          label: 'Trigger button',
          component: TriggerButtonSection,
          categories: ['button', 'popup'],
        },
        {
          key: 'heroimage',
          label: 'Hero image',
          component: HeroImageSection,
          categories: ['graphic', 'popup'],
        },
        {
          key: 'headline',
          label: 'Headline',
          component: HeadlineSection,
          categories: ['graphic', 'popup'],
        },
        {
          key: 'subheading',
          label: 'Subheading',
          component: SubheadingSection,
          categories: ['graphic', 'popup'],
        },
        {
          key: 'inputs',
          label: 'Inputs',
          component: InputsSection,
          categories: ['popup'],
        },
        {
          key: 'mainbutton',
          label: 'Main button',
          component: MainButtonSection,
          categories: ['popup'],
        },
        // {
        //   key: 'mobilebutton',
        //   label: 'Main button',
        //   component: MobileButtonSection,
        //   categories: ['popup'],
        //   device: 'mobile',
        // },
        {
          key: 'legalFooter',
          label: 'Legal footer',
          component: SubheadingSection,
          categories: ['graphic', 'popup'],
        },
      ],
      selectedMode: 'design',
      selectedTab: null,
      showImageModal: false,
      loadingExportImage: false,
      device: 'desktop',
    }
  },

  computed: {
    listSections() {
      return this.sections.filter(
        (section) =>
          section.categories.indexOf(this.coupon.type) !== -1 &&
          (!section.device || section.device === this.device)
      )
    },
  },

  methods: {
    onChooseType(type) {
      let coupon = JSON.parse(JSON.stringify(this.coupon))
      coupon.type = type
      this.$emit('done', coupon)
    },

    onTabClick(section) {
      this.selectedTab = section
    },

    selectMode(mode, device) {
      this.selectedMode = mode
      this.device = device
    },

    async validateStep() {
      let stepValid = false

      if (this.coupon.type) {
        stepValid = await this.update()
      }

      return stepValid
    },

    async update() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch('coupon/update', this.coupon)
        .then((coupon) => {
          this.$emit('done', coupon)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon-types {
  .card-img-top {
    max-width: 100%;
    width: 150px;
    margin: 0 auto;
    min-height: 150px;
  }
}

.card-wrapper {
  max-width: 1024px;
}

.editor-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  @media screen and (max-width: 992px) {
    .design-box {
      margin: 0 auto;
    }
    flex-direction: column;
  }
}
</style>

<template>
<div class="landing-builder-container">
  <div v-if="selectedMode === 'design'" class="design-box">
    <div class="card">
      <div class="card-body">
        <div role="tablist" class="custom-accordion ml-4">
          <b-card
            v-for="section in filteredSections"
            :key="section.key"
            class="mb-1"
            no-body
            >
            <b-card-header header-tag="header" role="tab">
              <a href="javascript: void(0);" class="text-black">
                <h5 class="m-0 font-size-14" @click="onTabClick(section.key)">
                  <i
                  :class="{
                      'uil-minus-circle text-warning':
                      selectedTab !== section.key,
                      'uil-plus-circle text-success':
                      selectedTab === section.key,
                  }"
                  class="uil h3 icon"
                  ></i>
                  {{ section.label }}
                </h5>
              </a>
            </b-card-header>
            <b-collapse
                accordion="accordion"
                role="tabpanel"
                :visible="selectedTab === section.key"
            >
                <b-card-body class="px-0">
                <component
                    :is="section.component"
                    :section="landing.sections[section.key]"
                />
                </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
  <div class="preview-box">
    <div class="preview-container">
      <b-tabs>
        <b-tab
          title="Design"
          active
          @click="selectMode('design')"
        >
          <Landing
            ref="widget"
            :landing="landing"
            :page-mode="selectedMode"
            :selected-tab="selectedTab"
            @select-block="onTabClick"
          />
        </b-tab>
        <b-tab title="Preview" @click="selectMode('preview')">
          <div>
            <Landing
              :landing="landing" 
              :page-mode="selectedMode"
              :selected-tab="selectedTab"
              @select-block="onTabClick"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</div>
</template>

<script>
import LogoSection from './Sections/LogoSection'
import StylingSection from './Sections/StylingSection'
import ActionButtonSection from './Sections/ActionButtonSection'
import MainSection from './Sections/MainSection'
import InputsSection from './Sections/InputsSection'
import LegalSection from './Sections/LegalSection'
import ThankSection from './Sections/ThanksSection'
import ScriptsSection from './Sections/ScriptsSection'
import Landing from './Landing'

export default {
  components: {
    Landing, LogoSection, StylingSection, ActionButtonSection,
    MainSection, InputsSection, LegalSection, ThankSection,
    ScriptsSection,
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Styling',
          component: StylingSection,
        },
        {
          key: 'logo',
          label: 'Logo',
          component: LogoSection,
        },
        {
          key: 'main',
          label: 'Main',
          component: MainSection,
        },
        {
          key: 'inputs',
          label: 'Inputs',
          component: InputsSection,
        },
        {
          key: 'actionButton',
          label: 'Action button',
          component: ActionButtonSection,
        },
        {
          key: 'legalFooter',
          label: 'Legal footer',
          component: LegalSection,
        },
        {
          key: 'thanks',
          label: 'Thanks',
          component: ThankSection,
        },
        {
          key: 'scripts',
          label: 'Custom scripts',
          component: ScriptsSection,
        },
      ],
      selectedTab: null,
      selectedMode: 'design',
    }
  },

  computed: {
    filteredSections() {
      return this.sections.filter(s => s.key !== 'thanks' || (['landing'].includes(this.landing.type)))
    }
  },

  methods: {
    selectMode(mode) {
      this.selectedMode = mode
    },

    onTabClick(section) {
      this.selectedTab = section
    },

    async validateStep() {
      let stepValid = false
      
      if (this.landing.type === 'landing')
        stepValid = true
      else 
        stepValid = await this.updateOrCreate()

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch(
          this.landing.id ? 'landing/update' : 'landing/create',
          this.landing
        )
        .then((landing) => {
          this.$emit('done', landing)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch(() => {
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
  }
}
</script>

<style lang="scss">
.landing-builder-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  .nav-tabs {
    justify-content: center;
    a {
      opacity: 0.6;
      &.active {
        background: transparent;
        color: #495057;
        opacity: 1;
      }
    }
  }
}
</style>
<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <ImageInput v-model="section.Image" label="Link to logo image file" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Link on clicking the logo</label>
        <input
          v-model="section.Link"
          type="text"
          class="form-control"
          placeholder="example.com"
        />
      </div>
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MaxWidth" label="Max. width" />
    </div>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import AlignInput from './AlignInput'
import ImageInput from './ImageInput'

export default {
  components: {
    NumberInput,
    AlignInput,
    ImageInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

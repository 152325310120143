<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-2">Full Screen</label>
        <b-form-checkbox
          v-model="section.Fullscreen"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background Color" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Background video</label>
        <input
          v-model="section.BackgroundVideo"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <ImageInput v-model="section.BackgroundImage" label="Background image" />
    </div>
    
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MaxWidth" label="Max. width" />
    </div>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import ImageInput from './ImageInput'
import ColorInput from './ColorInput'

export default {
  components: {
    NumberInput,
    ImageInput,
    ColorInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>

<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Padding" label="Padding" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.HeadingColor" label="Text color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <label>Answers</label>
      <AlignInput v-model="section.AnswerVoteAlign" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.AnswerFontSize" label="Font size" />
    </div>
  </div>
</template>

<script>
import ColorInput from '../Sections/ColorInput'
import NumberInput from '../Sections/NumberInput'
import AlignInput from '../Sections/AlignInput'

export default {
  components: {
    NumberInput,
    AlignInput,
    ColorInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
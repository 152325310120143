<template>
  <div v-if="section">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Label</label>
          <textarea v-model="section.Label" rows="3" type="text" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Text</label>
          <textarea v-model="section.Disclaimer" rows="9" type="text" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.DisclaimerColor" label="Color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.DisclaimerFontSize" label="Font size" />
      </div>
      <div class="col-md-12 d-flex justify-content-between mb-3">
        <label class="mr-4">Show CheckBox</label>
        <b-form-checkbox v-model="section.ShowDisclaimerCheckBox" switch></b-form-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ColorInput v-model="section.BackgroundColor" label="Background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BorderColor" label="Border color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Roudness" label="Corner roudness" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.Color" label="Color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.FontSize" label="Font size" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Padding" label="Padding" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from '../Sections/ColorInput'
import NumberInput from '../Sections/NumberInput'

export default {
  components: {
    ColorInput,
    NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style></style>
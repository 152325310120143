<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <h5 class="h5">Referral Rewards</h5>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-primary" @click="handleAddReward">
            Add Reward
          </button>
        </div>
      </div>
      <table class="table table-bordered mt-4">
        <tbody>
          <tr v-for="reward in landing.rewards" :key="`reward-${reward.id}`">
            <td style="vertical-align: middle;">
              <b-form-checkbox
                :checked="reward.active"
                size="sm"
                switch
                inline
                @change="handleChangeStatus(reward)"
              ><strong>{{ reward.name }}</strong></b-form-checkbox> 
            </td>
            <td>
              {{ reward.points_unlock }} Points
            </td>
            <td>
              <strong>Coupons Unlocked: {{ reward.unlock_no }}</strong>
            </td>
            <td>
              {{ reward.description }}
            </td>
            <td class="table-actions-group">
              <button
                class="btn btn-outline-dark"
                @click="handleEdit(reward)"
              >
                <i class="uil uil-edit"></i> Edit
              </button>
              <b-dropdown
                variant="outline-dark"
                right
              >
                <template v-slot:button-content>
                  <i class="uil uil-angle-down"></i>
                </template>
                <b-dropdown-item href="#"
                  :disabled="loadingRemove === reward.id"
                  @click="deleteReward(reward)">
                  <b-spinner v-if="loadingRemove === reward.id" small />
                  <span v-else class="text-danger">
                    <i class="uil uil-trash"></i> Delete
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <b-modal v-model="isShowModal" title="Reward" size="xl">
        <template v-if="selectedReward">
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                v-model="selectedReward.points_unlock"
                name="points_unlock"
                label="Points To Unlock"
              />
            </div>
            <div class="col-md-6">
              <SelectInput
                v-model="selectedReward.day_to_expire"
                :options="timeLimitExpireOptions"
                name="day_to_expire"
                label="Day to Expire"
              />
            </div>
            <div class="col-md-6">
              <label>Description</label>
              <textarea
                v-model="selectedReward.name"
                name="name"
                label="Name"
                rules="required"
                class="form-control"
                rows="3"
              ></textarea>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Description</label>
                <textarea
                  v-model="selectedReward.description"
                  name="message"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <MergeFieldsGroup :show-redemption="true" :hide-expire-date="true" :show-contact-link="true" :show-keyword="true" :hide-title="true" @selected="appendField"/>
          </div>

          <div class="form-group">
            <label>Content</label>
            <div class="d-block w-100 textarea-wrapper">
              <textarea v-model="selectedReward.content" class="form-control" @focus="selectEditor"></textarea>
              <span class="d-block opt-out"><small><i>Reply STOP to opt out</i> (this is appended to this message)</small></span>
            </div>
            <SMSLength :text="selectedReward.content" :has-optout="true"/>
          </div>

          <p>
            These messages will go out the number of days prior to the offer
            expiring, this creates urgency and increases conversion rates.
          </p>

          <table class="table table-bordered mt-4 mb-4">
            <thead>
              <tr>
                <th style="width:40%">Time</th>
                <th style="">Message</th>
                <th style="width:100px;"> </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(reminder, index) in selectedReward.reminders"
                :key="index"
              >
                <td>
                  <div class="row">
                    <div class="col-md-6">
                      <SelectInput
                        v-model="reminder.days"
                        :options="timeLimitOptions"
                        label="Days"
                        name="Days"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Time</label>
                      <TimePicker v-model="reminder.time" />
                    </div>
                  </div>
                </td>
                <td>
                  <ValidationProvider
                    v-slot="{ failed, errors }"
                    rules="required"
                    name="message"
                  >
                    <textarea
                      v-model="reminder.message"
                      name="message"
                      class="form-control mt-3"
                      rows="2"
                      :class="{ 'is-invalid': failed }"
                      @focus="selectEditor"
                    ></textarea>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                    <SMSLength :text="reminder.message" />
                  </ValidationProvider>
                </td>
                <td class="text-center align-middle">
                  <button
                    class="btn btn-outline-danger btn-sm"
                    type="button"
                    @click="removeRemimder(index)"
                  >
                    <i class="uil uil-trash-alt"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td class="text-center">
                  <button
                    class="btn btn-outline-success btn-sm"
                    type="button"
                    @click="addReminder"
                  >
                    <i class="uil uil-plus"></i> Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        

        <div class="mt-2">
          <strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Timezone: {{ settingTimezone }}</span></strong>
          <div>Current Time: {{ serverTime }}</div>
        </div>

        <template v-slot:modal-footer>
          <b-button variant="primary" :disabled="isLoadingSave" @click="save">
            <b-spinner v-if="isLoadingSave" small />
            <span v-else>Save</span>
            
          </b-button>
        </template>
      </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import SMSLength from '../../Common/SMSLength'

export default {
  components: { SMSLength },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      selectedEditor: null,
      newReward: {
        points_unlock: 1,
        day_to_expire: 14,
        reminders: [],
      },
      selectedReward: null,
      isLoadingSave: false,
      loadingRemove: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    serverTime() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return moment()
        .tz(timezone)
        .format('MMM D, YYYY hh:mm A')
    },

    settingTimezone() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },

    timeLimitExpireOptions() {
      return Array(31)
        .fill()
        .map((_, i) => {
          return { value: i + 1, label: i + 1 }
        })
    },

    timeLimitOptions() {
      return Array(32)
        .fill()
        .map((_, i) => {
          return { value: i, label: i }
        })
    },
  },

  methods: {
    addReminder() {
      this.selectedReward.reminders.push({ message: '', time: '08:00', days: 1 })
    },

    removeRemimder(index) {
      this.selectedReward.reminders.splice(index, 1)
    },

    selectEditor(event) {
      this.selectedEditor = event.target
    },

    appendField(field) {
      if (!this.selectedEditor) return
      this.selectedEditor.focus()
      document.execCommand('insertText', false, field)
    },

    handleAddReward() {
      this.selectedReward = Vue.util.extend({}, this.newReward)
      this.isShowModal = true
    },

    save() {
      let success = true
      this.selectedReward.reminders.forEach((item) => {
        success = success && !!item.message
      });

      if (!success) {
        this.$toast.open({
          message: 'Reminder Message can\'t be empty',
          type: 'default',
          duration: 3000,
        })
      } else {
        this.updateOrCreateReward()
      }
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.updateOrCreate()
        }
      })

      return stepValid
    },

    updateOrCreateReward() {
      this.isLoadingSave = true

       this.$store
        .dispatch(
          this.selectedReward.id ? 'landing/updateReward' : 'landing/createReward',
          { ...this.selectedReward, coupon_id: this.landing.id }
        )
        .then((landing) => {
          this.$emit('done', landing)
          this.isShowModal = false
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },

    handleEdit(reward) {
      this.selectedReward = Vue.util.extend({}, reward)
      this.isShowModal = true
    },

    handleChangeStatus(reward) {
      this.$store
        .dispatch('landing/changeRewardStatus', reward)
        .then((landing) => {
          this.$emit('done', landing)
        })
        .catch(() => {
        })
    },

    deleteReward(reward) {
      this.loadingRemove = reward.id

      this.$store
        .dispatch('landing/deleteReward', reward)
        .then((landing) => {
          this.$emit('done', landing)
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0px;
  text-align: center;
}
</style>
<template>
  <div class="card">
    <div class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }" ref="createForm">
        <form @submit.prevent="handleSubmit(create)">
          <div class="row">
            <div class="col-md-6">
              <TextInput
                v-model="landing.name"
                name="name"
                rules="required"
                label="Name"
              />
            </div>
            <div class="col-md-6">
              <ValidationProvider
                v-slot="{ failed, errors }"
                rules="required"
                name="keyword"
              >
                <label>Keyword</label>
                <multiselect
                  v-model="landing.keyword"
                  :options="keywords"
                  :class="{ 'is-invalid': failed }"
                  placeholder="Select keyword"
                  :taggable="true"
                  @tag="addKeyword"
                ></multiselect>
                <b-form-invalid-feedback v-if="errors.length">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                v-slot="{ failed, errors }"
                rules="required"
                name="discount_type"
              >
                <div class="form-group">
                  <label>Discount type</label>
                  <multiselect
                    v-model="landing.discount_type"
                    :options="discountTypes"
                    :class="{ 'is-invalid': failed }"
                    placeholder="Select discount type"
                  ></multiselect>
                  <b-form-invalid-feedback v-if="errors.length">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider
                    v-slot="{ failed, errors }"
                    rules="required"
                    name="type"
                  >
                    <div class="form-group">
                      <label>Coupon Type</label>
                      <multiselect
                        v-model="selectedCouponType"
                        :options="couponTypes"
                        :class="{ 'is-invalid': failed }"
                        placeholder="Select Coupon Type"
                        track-by="id"
                        label="label"
                        :allow-empty="false"
                      ></multiselect>
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </ValidationProvider>
                </div>
                <div v-if="selectedCouponType && selectedCouponType.id === 2" class="col-md-12">
                  <TextInput
                    v-model="landing.coupon_code"
                    name="couponCode"
                    rules="required"
                    label="Coupon Code"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      keywords: [],
      selectedCouponType: null,
    }
  },

  computed: {
    discountTypes() {
      return this.$store.getters['landing/discountTypes']
    },

    couponTypes() {
      return this.$store.getters['coupon/couponTypes']
    },
  },

  watch: {
    'landing.coupon_type': function(val) {
      if (val) {
        this.selectedCouponType = this.couponTypes.find(item => item.id === val)
      }
    }
  },

  mounted() {
    this.selectedCouponType = this.couponTypes[0]
    if (this.landing && this.landing.coupon_type)
      this.selectedCouponType = this.couponTypes.find(item => item.id === this.landing.coupon_type)

    this.getKeywords()
  },


  methods: {
    addKeyword(keyword) {
      this.keywords.push(keyword)
      this.landing.keyword = keyword
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywords', { type: ['automation', 'popup'] })
        .then((keywords) => {
          this.keywords = keywords.map(m => m.name)
        })
        .catch(() => {
        })
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.updateOrCreate()
        }
      })

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)
      if (this.selectedCouponType)
      {
        this.landing.coupon_type = this.selectedCouponType.id
      }

      await this.$store
        .dispatch(
          this.landing.id ? 'landing/update' : 'landing/create',
          this.landing
        )
        .then((landing) => {
          this.$emit('done', landing)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },

  },
}
</script>

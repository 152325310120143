<template>
  <div v-if="section" class="row">
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BorderColor" label="Border color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.LabelColor" label="Label Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-12 mb-4">
      <h6>Options</h6>
      <div v-for="(item, index) in voteOptions" :key="`item-${index}`" class="d-flex align-items-center mb-2">
        <input v-model="item.text" type="text" class="form-control" :placeholder="item.placeholder" />
        <button class="ml-1 btn btn-danger btn-sm float-right" href="javascript:void;" @click.prevent="onDeleteOption(index)">
        <i class="uil uil-trash"></i>
      </button>
      </div>
      <button class="ml-1 btn btn-primary btn-sm float-right mt-3" href="javascript:void;" @click.prevent="onAddOption">
        <i class="uil uil-plus"></i> New Option
      </button>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-4">Allow more than one answer?</label>
        <b-form-checkbox v-model="section.AllowMultiple" switch></b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '../Sections/NumberInput'
import ColorInput from '../Sections/ColorInput'

export default {
  components: {
    NumberInput,
    ColorInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      colorList: [
        'bg-primary', 'bg-success', 'bg-danger', 'bg-warning', 'bg-info', 'bg-dark'  
      ]
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    voteOptions() {
      if (!this.landing.voteOptions || !this.landing.voteOptions.length) {
        // eslint-disable-next-line
        this.landing.voteOptions = [
          { placeholder: 'Option 1', text: 'Option 1', color: 'bg-primary' },
          { placeholder: 'Option 2', text: 'Option 2', color: 'bg-primary' },
          { placeholder: 'Option 3', text: 'Option 3', color: 'bg-primary' },
        ]
      }
      return this.landing.voteOptions
    },
  },

  mounted() {
  },

  methods: {
    onAddOption() {
      if (this.voteOptions.length) {
        if (!this.voteOptions[this.voteOptions.length - 1]['text'])
          return;
      }
      let index = this.voteOptions.length + 1
      this.voteOptions.push({ 
        placeholder: 'Answer ' + index,
        color: this.colorList[(index - 1) % 6]
      })
    },
    onDeleteOption(index) {
      this.voteOptions.splice(index, 1)
    },
  }
}
</script>

<style lang="scss">
.landing-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>
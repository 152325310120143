<template>
  <form-wizard
    ref="formWizard"
    color="#4284f4"
    @on-complete="handleOnComplete"
    @on-change="handleOnChange"
  >
    <tab-content
      title="Create landing"
      :before-change="() => $refs.createCampaignStep.validateStep()"
    >
      <CreateStep
        ref="createCampaignStep"
        :landing="landing"
        @done="(landing) => $emit('landing-changed', landing)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content
      v-if="landing.type === 'vote'"
      title="Vote builder"
      :before-change="() => $refs.voteStep.validateStep()"
    >
      <VoteStep
        v-if="landing && landing.votes"
        ref="voteStep"
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)"
      />
    </tab-content>
    <tab-content
      title="Landing builder"
      :before-change="() => $refs.buildStep.validateStep()"
    >
      <BuildStep
        ref="buildStep"
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)"
      />
    </tab-content>
    <tab-content
      v-if="landing.type === 'vote'"
      title="Vote Result"
      :before-change="() => $refs.voteResultStep.validateStep()"
    >
      <VoteResultStep
        v-if="landing && landing.votes"
        ref="voteResultStep"
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)"
      />
    </tab-content>
    <tab-content
      v-if="landing.type === 'loop'"
      title="Sharing"
      :before-change="() => $refs.shareStep.validateStep()"
    >
      <SharingStep 
        v-if="landing && landing.loops"
        ref="shareStep"  
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)"
      ></SharingStep>
    </tab-content>
    <tab-content
      v-if="landing.type === 'loop'"
      title="Reward"
    >
      <RewardStep 
        v-if="landing && landing.loops"
        ref="rewardStep"
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)"
      ></RewardStep>
    </tab-content>
    <tab-content
      v-if="landing.type === 'loop'"
      title="Sharing Page">
      <ThanksStep
        v-if="landing && landing.loops"
        :landing="landing"
        @loading="loadingState"
        @done="(landing) => $emit('landing-changed', landing)" />
    </tab-content>
    <tab-content
      v-if="landing.widget"
      title="Website Widget"
      :before-change="() => $refs.couponWizardStep.validateStep()"
    >
      <CounponWizardStep
        ref="couponWizardStep"
        :coupon="landing.widget"
        @done="(widget) => $emit('coupon-changed', { ...landing, widget: widget })"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content v-if="landing.widget" title="Code Generation" >
      <CodeGenerationStep :coupon-script="couponScript" :coupon="landing.widget" />
    </tab-content>

    <template slot="next">
      <button
        tabindex="-1"
        type="button"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Next</span>
      </button>
    </template>
    <template slot="finish">
      <button
        v-if="!landing.new_keyword && landing.automation_id"
        type="button"
        class="wizard-btn ml-2" style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        @click.stop="handleShowWarning">
        Go To Automation
      </button>
      <button
        v-if="landing.type === 'loop' && !landing.widget"
        tabindex="-1"
        type="button"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #34a853;border-color: #34a853;"
        @click.stop="createWidget"
      >
        <span>Create Widget</span>
      </button>
      <button
        v-if="landing.type === 'loop' && landing.widget"
        tabindex="-1"
        type="button"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #ea4335;border-color: #ea4335;"
        @click.stop="deleteWidget"
      >
        <span>Delete Widget</span>
      </button>
      <button
        tabindex="-1"
        type="button"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Save</span>
      </button>
      <a
        href="javascript:;"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        :disabled="isLoading"
        @click.prevent="exit"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Save & Exit</span>
      </a>
    </template>
    <b-modal v-model="showWidgetModal" title="Create a Widget">
      <p>Are you sure you want to create a Widget for this Loop?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showWidgetModal = false"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="onCreateWidget">
          <b-spinner v-if="isLoading" small />
          <span v-else>Create</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="deleteWidgetModal" title="Delete Widget">
      <p>Are you sure you want to delete the Widget for this Loop?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="deleteWidgetModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="onDeleteWidget">
          <b-spinner v-if="isLoading" small />
          <span v-else>Delete</span>
        </b-button>
      </template>
    </b-modal>
  </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import CreateStep from './CreateStep'
import BuildStep from './BuildStep'
import VoteStep from './VoteStep'
import VoteResultStep from './VoteResultStep'
import SharingStep from './SharingStep.vue'
import RewardStep from './RewardStep.vue'
import ThanksStep from './ThanksStep.vue'
import CodeGenerationStep from '../Coupons/Components/CodeGenerationStep'
import CounponWizardStep from '../Coupons/Components/CounponWizardStep'
import moment from 'moment-timezone'

export default {
  components: {
    FormWizard, TabContent, CreateStep, BuildStep, SharingStep, RewardStep,
    ThanksStep, CodeGenerationStep, CounponWizardStep, VoteStep, VoteResultStep
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      couponScript: null,
      constLanding: null,
      showWidgetModal: false,
      deleteWidgetModal: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.constLanding = JSON.parse(JSON.stringify(this.landing))
  },

  methods: {
    loadingState(loading) {
      this.isLoading = loading
    },

    createWidget() {
      this.showWidgetModal = true
    },

    onCreateWidget() {
      this.isLoading = true;
      
      const coupon = JSON.parse(
        JSON.stringify(this.$store.getters['coupon/couponOriginal'])
      )
      coupon.keyword = this.landing.keyword
      coupon.widget_id = this.landing.id
      coupon.discount_type = this.landing.discount_type
      coupon.sections.mobilebutton.Number = this.user.business.phone_number
      coupon.timezone = moment.tz.guess()

      this.$store
        .dispatch('coupon/create', coupon)
        .then((res) => {
          const landingUpdated = JSON.parse(JSON.stringify(this.landing))
          landingUpdated.widget = res
          this.$emit('landing-changed', landingUpdated)
          
          setTimeout(() => {
            this.isLoading = false;
            this.showWidgetModal = false
            this.$refs.formWizard.nextTab();
          }, 500);
        })
        .catch(() => {
          this.showWidgetModal = false

        })
    },

    deleteWidget() {
      this.deleteWidgetModal = true
    },

    onDeleteWidget() {
      this.isLoading = true;
      
      this.$store
        .dispatch('coupon/destroy', this.landing.widget.id)
        .then(() => {
          const landingUpdated = JSON.parse(JSON.stringify(this.landing))
          landingUpdated.widget = undefined
          this.$emit('landing-changed', landingUpdated)

          this.isLoading = false;
          this.deleteWidgetModal = false
        })
        .catch(() => {
          this.deleteWidgetModal = false

        })
    },

    handleOnComplete() {
      this.isLoading = true
      this.$store.dispatch('landing/update', this.landing)
          .then(() => {
            this.constLanding = JSON.parse(JSON.stringify(this.landing))
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
    },

    exit() {
      this.isLoading = true
      this.$store.dispatch('landing/update', this.landing)
        .then(() => {
          if (this.landing.new_keyword && this.landing.automation_id) {
            this.$router.push({ name: 'business.automations.edit', params: { id: this.landing.automation_id } })
          } else {
            this.$router.push({ name: 'business.coupons.index' })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    handleShowWarning() {
      if (Object.keys(this.difference(this.landing, this.constLanding)).length) {
        if (window.confirm('Are you sure you want to leave without saving?')) {
          this.$router.push({ name: 'business.automations.edit', params: { id: this.landing.automation_id } })
        }
      } else {
        this.$router.push({ name: 'business.automations.edit', params: { id: this.landing.automation_id } })
      }
    },

    getScript() {
      this.$store
        .dispatch('coupon/getScript', this.landing.widget.id)
        .then((script) => {
          this.couponScript = script
        })
        .catch(() => {})
    },

    handleOnChange(prevIndex, nextIndex) {
      if (nextIndex === 6 && !this.couponScript) {
        this.getScript()
      }
    },
  },
}
</script>

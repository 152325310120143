<template>
  <div style="display:flex;justify-content:center;">
    <div
      v-if="coupon.type === 'graphic' || coupon.type === 'popup'"
      class="sms-widget" :style="smsWidgetWrapperStyle"
    >
      <div ref="graphicContainer" :style="smsWidgetContainerStyle">
        <div
          class="widget-block"
          :class="{ 'widget-content': coupon.sections.logo.Image }"
          @click="selectBlock('logo')"
        >
          <div
            v-if="coupon.sections.logo.Image"
            class="logo-box"
            :style="{ textAlign: coupon.sections.logo.Align }"
          >
            <a :href="coupon.sections.logo.Link">
              <img
                :src="coupon.sections.logo.Image"
                alt="Logo"
                :style="{
                  maxWidth: coupon.sections.logo.MaxWidth + 'px',
                  width: '100%',
                }"
              />
            </a>
          </div>
          <div v-else class="widget-default">
            <img src="@assets/images/widgets/logo-section.svg" />
            <div class="widget-caption">
              Logo <span class="widget-optional">(optional)</span>
            </div>
          </div>
        </div>
        <div
          class="widget-block"
          :class="{ 'widget-content': coupon.sections.heroimage.Image }"
          @click="selectBlock('heroimage')"
        >
          <div
            v-if="coupon.sections.heroimage.Image"
            class="heroimage-box"
            :style="{ textAlign: coupon.sections.heroimage.Align }"
          >
            <img
              :src="coupon.sections.heroimage.Image"
              alt="Logo"
              :style="{
                maxWidth: coupon.sections.heroimage.MaxWidth + 'px',
                width: '100%',
              }"
            />
          </div>
          <div v-else class="widget-default">
            <img src="@assets/images/widgets/hero-section.svg" />
            <div class="widget-caption">
              Hero image <span class="widget-optional">(optional)</span>
            </div>
          </div>
        </div>
        <div
          class="widget-block"
          :class="{ 'widget-content': coupon.sections.headline.Content }"
          @click="selectBlock('headline')"
        >
          <div
            v-if="coupon.sections.headline.Content"
            :style="{
              color: coupon.sections.headline.Color,
              fontSize: `${coupon.sections.headline.FontSize}px`,
              textAlign: `${coupon.sections.headline.Align}`,
            }"
          >
            {{ coupon.sections.headline.Content }}
          </div>
          <div v-else class="widget-default">
            <img src="@assets/images/widgets/call-text-section.svg" />
            <div class="widget-caption">
              Headline
            </div>
          </div>
        </div>
        <div
          class="widget-block"
          :class="{ 'widget-content': subheadingContent }"
          @click="selectBlock('subheading')"
        >
          <div
            v-if="subheadingContent"
            :style="{
              color: coupon.sections.subheading.Color,
              fontSize: `${coupon.sections.subheading.FontSize}px`,
              textAlign: `${coupon.sections.subheading.Align}`,
            }"
          >
            {{ subheadingContent }}
          </div>
          <div v-else class="widget-default">
            <img src="@assets/images/widgets/sec-text-section.svg" />
            <div class="widget-caption">
              Subheading <span class="widget-optional">(optional)</span>
            </div>
          </div>
        </div>
        <div
          v-if="showInputsSection"
          class="widget-block widget-content mb-2"
          @click="selectBlock('inputs')"
        >
          <div v-if="coupon.sections.inputs.ShowName" class="name-input mb-2">
            <input
              type="email"
              :placeholder="coupon.sections.inputs.NamePlaceholder"
              :style="inputsStyle"
              readonly
            />
          </div>

          <div v-if="coupon.sections.inputs.ShowEmail" class="email-input mb-2">
            <input
              type="email"
              :placeholder="coupon.sections.inputs.EmailPlaceholder"
              :style="inputsStyle"
              readonly
            />
          </div>

          <div v-if="coupon.sections.inputs.ShowPhone" class="phone-input" :style="phoneInputsStyle">
            <div class="phone-code">
              <img src="@assets/images/flag-us.png" />
              <span class="code">+1</span>
            </div>
            <input
              type="text"
              :placeholder="coupon.sections.inputs.PhonePlaceholder"
              readonly
            />
          </div>
        </div>
        <div
          class="widget-block widget-content"
          @click="selectBlock('mainbutton')"
        >
          <div style="text-align:center;">
            <a :style="mainButtonStyle">
              <i
                v-if="coupon.sections.mainbutton.Type === 'icon-left'"
                class="icon"
                :class="coupon.sections.mainbutton.Icon"
              ></i>
              {{ coupon.sections.mainbutton.Label }}
              <i
                v-if="coupon.sections.mainbutton.Type === 'icon-right'"
                class="icon"
                :class="coupon.sections.mainbutton.Icon"
              ></i>
            </a>
          </div>
        </div>
        <!-- <div
          v-if="coupon.sections.mobilebutton && device === 'mobile'"
          class="widget-block widget-content"
          @click="selectBlock('mobilebutton')"
        >
          <div style="text-align:center;">
            <a :style="mobileButtonStyle">
              <i
                v-if="coupon.sections.mobilebutton.Type === 'icon-left'"
                class="icon"
                :class="coupon.sections.mobilebutton.Icon"
              ></i>
              {{ coupon.sections.mobilebutton.Label }}
              <i
                v-if="coupon.sections.mobilebutton.Type === 'icon-right'"
                class="icon"
                :class="coupon.sections.mobilebutton.Icon"
              ></i>
            </a>
          </div>
        </div> -->
        <div
          class="widget-copyright"
          :style="{ textAlign: `${coupon.sections.legalFooter.Align}` }"
        >
          <a
            :style="{
              color: coupon.sections.legalFooter.Color,
              fontSize: `${coupon.sections.legalFooter.FontSize}px`,
            }"
          >
            {{ coupon.sections.legalFooter[contentAttr] | legal }}
          </a>
        </div>
      </div>
      <div v-if="user.business.agency.whitelabel.powered_enabled" class="text-right mt-1 powered-by">
        <span class="powered-by-content">powered by <a :href="user.business.agency.whitelabel.powered_url" target="_blank">{{ user.business.agency.whitelabel.powered_name }} </a></span>
      </div>
      <div v-if="coupon.type === 'popup'" class="widget-toggle">
        <div :style="triggerButtonStyle" @click="selectBlock('triggerButton')">
          <i
            v-if="coupon.sections.triggerButton.Type === 'icon-left'"
            class="icon"
            :class="coupon.sections.triggerButton.Icon"
          ></i>
          {{ coupon.sections.triggerButton.Label }}
          <i
            v-if="coupon.sections.triggerButton.Type === 'icon-right'"
            class="icon"
            :class="coupon.sections.triggerButton.Icon"
          ></i>
        </div>
      </div>
    </div>
    <div v-if="coupon.type === 'button'" style="margin-top:100px;">
      <a :style="triggerButtonStyle">
        <i
          v-if="coupon.sections.triggerButton.Type === 'icon-left'"
          class="icon"
          :class="coupon.sections.triggerButton.Icon"
        ></i>
        {{ coupon.sections.triggerButton.Label }}
        <i
          v-if="coupon.sections.triggerButton.Type === 'icon-right'"
          class="icon"
          :class="coupon.sections.triggerButton.Icon"
        ></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coupon: {
      type: [Object],
      default: null,
    },

    device: {
      type: String,
      default: 'desktop',
    },
  },

  computed: {
    contentAttr() {
      let attr = null

      switch (this.device) {
        case 'desktop':
          attr = 'Content'
          break
        case 'mobile':
          attr = 'MobileContent'
          break
        case 'print':
          attr = 'PrintContent'
          break
      }

      return attr
    },
    user() {
      return this.$store.getters['auth/user']
    },

    showInputsSection() {
      return this.coupon.sections.inputs.ShowName || this.coupon.sections.inputs.ShowEmail || this.coupon.sections.inputs.ShowPhone
    },

    subheadingContent() {
      let content = ''

      switch (this.device) {
        case 'desktop':
          content = this.coupon.sections.subheading.Content
          break
        case 'mobile':
          content = this.coupon.sections.subheading.MobileContent
          break
      }
      if (content)
        content = content
          .replace(/<keyword>/, `"${this.coupon.keyword}"`)
          .replace(/<phone number>/, this.user.business.phone_number)
      return content
    },

    smsWidgetWrapperStyle() {
      return {
        width: `${this.coupon.sections.styling.MaxWidth}px`,
      }
    },

    smsWidgetContainerStyle() {
      return {
        backgroundColor: this.coupon.sections.styling.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.coupon.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
        borderRadius: `${this.coupon.sections.styling.Roudness}px`,
        boxShadow: '0 5px 25px 0 rgba(0,0,0,.20)',
        padding: '20px',
      }
    },

    triggerButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.triggerButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.triggerButton.BorderColor || 'transparent',
        color: this.coupon.sections.triggerButton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.triggerButton.Roudness}px`,
        fontSize: `${this.coupon.sections.triggerButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },

    inputsStyle() {
      return {
        backgroundColor: this.coupon.sections.inputs.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.inputs.BorderColor || 'transparent',
        color: this.coupon.sections.inputs.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.inputs.Roudness}px`,
        fontSize: `${this.coupon.sections.inputs.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
        width: '100%',
      }
    },

    phoneInputsStyle() {
      return {
        backgroundColor: this.coupon.sections.inputs.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.inputs.BorderColor || 'transparent',
        color: this.coupon.sections.inputs.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.inputs.Roudness}px`,
        fontSize: `${this.coupon.sections.inputs.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px`,
        width: '100%',
      }
    },

    mainButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mainbutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mainbutton.BorderColor || 'transparent',
        color: this.coupon.sections.mainbutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mainbutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mainbutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },

    mobileButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mobilebutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mobilebutton.BorderColor || 'transparent',
        color: this.coupon.sections.mobilebutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mobilebutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mobilebutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },
  },

  methods: {
    selectBlock(block) {
      this.$emit('selected-block', block)
    },

    getExportElement() {
      return this.$refs.graphicContainer
    },
  },
}
</script>

<style lang="scss" scoped>
.sms-widget {
  background-color: transparent;
  .powered-by-content {
    color: #666;
    padding-right: 10px;
    padding-top: 5px;
    display: block;
    font-size: 14px;
    text-align: center;
    a {
      color: #2499ef;
      font-weight: bold;
      &:hover {
        text-decoration: underline!important;
      };
    }
  }
  .widget-block {
    border: 2px dashed rgba(0, 27, 72, 0.12);
    border-radius: 8px;
    cursor: pointer;
    transition: border 300ms ease, background-color 300ms ease;
    margin-bottom: 1.4vh;
    position: relative;
    &.widget-content {
      border-color: transparent;
    }
    &:hover {
      border-color: #2399f0 !important;
      background-color: rgba(35, 153, 240, 0.05);
    }
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
      display: block;
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    align-items: center;
    display: flex;
    .phone-code {
      position: absolute;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      background-color: inherit;
      border: none;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }
}
</style>

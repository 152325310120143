<template>
    <div v-if="section" class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label>Custom JS Script</label>
                <textarea
                v-model="section.Custom"
                class="form-control"
                rows="3"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  components: {
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<template>
  <div class="card">
    <div v-if="landing.points" class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }" ref="createForm">
        <form @submit.prevent="handleSubmit(create)">
          <h5 class="h5">Points For Opt-in & Referrals</h5>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <b-form-checkbox
                    v-model="landing.points[`OptIn`].active"
                    switch
                    inline
                  ></b-form-checkbox> Opt-In
                </td>
                <td></td>
                <td></td>
                <td class="text-center">
                  <NumberInput v-model="landing.points['OptIn'].points" />
                </td>
              </tr>
              <tr>
                <td width="220px">
                  <b-form-checkbox
                    v-model="landing.points[`ReferralOptIn`].active"
                    switch
                    inline
                  ></b-form-checkbox> Referral Opt-In
                </td>
                <td></td>
                <td></td>
                <td class="text-center">
                  <NumberInput v-model="landing.points['ReferralOptIn'].points" />
                </td>
              </tr>
              <tr v-if="landing.points[`ShareText`]">
                <td>
                  <b-form-checkbox
                    v-model="landing.points[`ShareText`].active"
                    switch
                    inline
                  ></b-form-checkbox> Share Text
                </td>
                <td colspan="2">
                  <MergeFieldsGroup :text-field="$refs.selectedEditor" :show-contact-link="true" :hide-title="true"/>
                  <TextAreaAction ref="selectedEditor" v-model="landing.points['ShareText'].text" label="Content" 
                    :sms-length="true" :hide-upload="true" rules="required" name="message" />
                </td>
                <td class="text-center">
                  <NumberInput v-model="landing.points['ShareText'].points" />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h5 class="h5">Point For Sharing On Social Media</h5>
                </td>
              </tr>
              <tr v-for="(social, index) in socials" :key="`social-${index}`">
                <td class="d-flex">
                  <b-form-checkbox
                    v-model="landing.points[`Click${social}`].active"
                    switch
                    inline
                  ></b-form-checkbox>
                  <i v-if="landing.points[`Click${social}`].image && landing.points[`Click${social}`].image.match(/^fa/)"     style="margin-right: 5px; width: 32px; display: inline-block; border-radius: 5px; height: 32px;" :style="{ backgroundColor: landing.points[`Click${social}`].itemBackground }">
                    <span :style="{ color: landing.points[`Click${social}`].itemColor }"
                          style="justify-content: center; display: flex; align-items: center; font-size: 22px; line-height: 32px;"
                          :class="landing.points[`Click${social}`].image" 
                          > </span>
                  </i>
                  <img v-else :src="landing.points[`Click${social}`].image ? landing.points[`Click${social}`].image : require(`@assets/images/socials/${social}Fill.png`)" style="height: 32px; width: 32px;vertical-align: bottom;">
                  <!-- <img :src="require(`@assets/images/socials/${social}Fill.png`)" style="width: 32px;vertical-align: bottom;"> -->
                </td>
                <td>
                  <input
                    v-model="landing.points[`Click${social}`].label"
                    type="text"
                    class="form-control"
                  />
                </td>
                <td>
                  <!-- <input v-model="landing.points[`Click${social}`].link" type="text" class="form-control" placeholder="example.com"> -->
                </td>
                <td>
                  <NumberInput v-model="landing.points[`Click${social}`].points" />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h5 class="h5">Points For Liking & Following On Social Media</h5>
                </td>
              </tr>
              <tr v-for="(social, index) in socials" :key="`share-${index}`">
                <td class="d-flex">
                  <b-form-checkbox
                    v-model="landing.points[`Share${social}`].active"
                    switch
                    inline
                  ></b-form-checkbox>
                  <i v-if="landing.points[`Share${social}`].image && landing.points[`Share${social}`].image.match(/^fa/)"     style="margin-right: 5px; width: 32px; display: inline-block; border-radius: 5px; height: 32px;" :style="{ backgroundColor: landing.points[`Share${social}`].itemBackground }">
                    <span :style="{ color: landing.points[`Share${social}`].itemColor }"
                          style="justify-content: center; display: flex; align-items: center; font-size: 22px; line-height: 32px;"
                          :class="landing.points[`Share${social}`].image" 
                          > </span>
                  </i>
                  <img v-else :src="landing.points[`Share${social}`].image ? landing.points[`Share${social}`].image : require(`@assets/images/socials/${social}Fill.png`)" style="height: 32px; width: 32px;vertical-align: bottom;">
                  <!-- <img :src="require(`@assets/images/socials/${social}Fill.png`)" style="width: 32px;vertical-align: bottom;"> -->
                </td>
                <td>
                  <input
                    v-model="landing.points[`Share${social}`].label"
                    type="text"
                    class="form-control"
                  />
                </td>
                <td>
                  <input v-model="landing.points[`Share${social}`].link" type="text" class="form-control" placeholder="example.com">
                </td>
                <td>
                  <NumberInput v-model="landing.points[`Share${social}`].points" />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h5 class="h5">Custom Urls</h5>
                </td>
                <td>
                  <button
                      tabindex="-1"
                      type="button"
                      class="wizard-btn float-right"
                      style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
                       @click="handleAddCustomSocial"
                    >
                      <span>Add</span>
                    </button>
                </td>
              </tr>
              <tr v-for="(social, index) in landing.custom_urls" :key="`social-${index}`">
                <td class="d-flex">
                  <b-form-checkbox
                    v-model="landing.custom_urls[`${index}`].active"
                    switch
                    inline
                  ></b-form-checkbox>
                  <i v-if="landing.custom_urls[`${index}`].image && landing.custom_urls[`${index}`].image.match(/^fa/)"     style="margin-right: 5px; width: 32px; display: inline-block; border-radius: 5px; height: 32px;" :style="{ backgroundColor: landing.custom_urls[`${index}`].itemBackground }">
                    <span :style="{ color: landing.custom_urls[`${index}`].itemColor }"
                          style="justify-content: center; display: flex; align-items: center; font-size: 22px; line-height: 32px;"
                          :class="landing.custom_urls[`${index}`].image" 
                          > </span>
                  </i>
                  <img v-else :src="landing.custom_urls[`${index}`].image ? landing.custom_urls[`${index}`].image : require(`@assets/images/socials/UrlFill.png`)" style="width: 32px; height: 32px; vertical-align: bottom;">
                  <IconInput
                    v-model="landing.custom_urls[`${index}`].image">
                  </IconInput>
                </td>
                <td>
                  <input
                    v-model="landing.custom_urls[`${index}`].label"
                    type="text"
                    class="form-control"
                  />
                </td>
                <td>
                  <input v-model="landing.custom_urls[`${index}`].link" type="text" class="form-control" placeholder="example.com">
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <NumberInput v-model="landing.custom_urls[`${index}`].points" />
                    <button class="btn btn-danger mb-3 ml-3" type="button" @click="removeCustomUrl(index)">
                      <i class="uil uil-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </ValidationObserver>
    </div>
      <b-modal v-model="customUrlModal" title="Create a Custom Action" size="md">
        <template>
          <div class="row">
            <div class="col-md-12">
                <TextInput
                  v-model="customUrls[`CustomSocial${customUrlCounter}`].label"
                  name="tite"
                  label="What action would you like your user to take?"
                  placeholder="Write your own custom action"
                  rules="required"
                />
            </div>
          </div>
          <hr>
          <div class="row d-flex">
            <div class="col-md-6 d-flex">
              <IconInput
                v-model="customUrls[`CustomSocial${customUrlCounter}`].image" label="Item Icon">
              </IconInput>
              <i v-if="customUrls[`CustomSocial${customUrlCounter}`].image && customUrls[`CustomSocial${customUrlCounter}`].image.match(/^fa/)" style="margin-right: 5px; width: 32px; display: inline-block; border-radius: 5px; height: 32px;  margin-top: 32px;" :style="{ backgroundColor: customUrls[`CustomSocial${customUrlCounter}`].itemBackground }">
                  <span :style="{ color: customUrls[`CustomSocial${customUrlCounter}`].itemColor }"
                        style="justify-content: center; display: flex; align-items: center; font-size: 22px; line-height: 32px;"
                        :class="customUrls[`CustomSocial${customUrlCounter}`].image" 
                        > </span>
                </i>
                <img v-else :src="customUrls[`CustomSocial${customUrlCounter}`].image ? customUrls[`CustomSocial${customUrlCounter}`].image : require(`@assets/images/socials/UrlFill.png`)" style="width: 32px;vertical-align: bottom; margin-right: 5px; margin-top: 32px; height: 32px;">
            </div>
            <div class="col-md-6">
              <ColorInput v-model="customUrls[`CustomSocial${customUrlCounter}`].itemBackground" label="Item background" />
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6">
                <TextInput
                  v-model="customUrls[`CustomSocial${customUrlCounter}`].link"
                  name="url"
                  label="Where will you send your user?"
                  placeholder="example.com"
                  rules="required"
                />
            </div>
            <div class="col-md-6">
                <NumberInput 
                  v-model="customUrls[`CustomSocial${customUrlCounter}`].points"
                  label="Points for action completion." />
            </div>
          </div>
        </template>
        <template v-slot:modal-footer>
          <b-button variant="primary" :disabled="isLoadingSave" @click="saveCustomSocial">
            <b-spinner v-if="isLoadingSave" small />
            <span v-else>Save</span>
          </b-button>
        </template>
      </b-modal>
  </div>
</template>

<script>
import IconInput from '../Landings/Sections/IconInput'
import ColorInput from '../Landings/Sections/ColorInput'

export default {
  components: {
    IconInput,
    ColorInput
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      customUrlCounter: 1,
      customUrls: {
          'CustomSocial1' :{
              link: '',
              active: false,
              points: 1, 
              label: '', 
              image: '',
              itemBackground: '#3b5998',
              itemColor: '#FFFFFF'
            }
        },
      socials: ['Facebook', 'Twitter', 'Linkedin', 'Pinterest'],
      customUrlModal: false,
      isLoadingSave: false,
      tags: {
        'Share': 'Share us on',
        'Comment': 'Comment us on',
        'Like': 'Like us on',
        'Post': 'Post us on',
        'Follow': 'Follow us on',
        'Subscribe': 'Subscribe to',
        'Tweet': 'Tweet at',
        'Join': 'Join us on',
        'Visit': 'Visit us on',
        'Watch': 'Watch us on',
        'Signup': 'Signup on',
        'Download': 'Download',
        'Answer': 'Answer',
      },
    }
  },

  mounted() {
    if (!this.landing.points) {
      this.landing.points = {
        OptIn: { active: true, points: 1 },
        ReferralOptIn: { active: true, points: 1 },
        ShareText: { active: false, points: 1, text: 'Check out this cool offer that i just found Follow this link. To check it out', label: 'Share Text', itemBackground: '#548235', itemColor: '#FFFFFF' },
        ClickFacebook: { active: false, points: 1, label: 'Share on Facebook', itemBackground: '#3b5998', itemColor: '#FFFFFF' },
        ClickTwitter: { active: false, points: 1, label: 'Share on Twitter', itemBackground: '#1da1f2', itemColor: '#FFFFFF'   },
        ClickLinkedin: { active: false, points: 1, label: 'Share on Linkedin', itemBackground: '#007bb5', itemColor: '#FFFFFF'   },
        ClickPinterest: { active: false, points: 1, label: 'Share on Pinterest', itemBackground: '#cb2027', itemColor: '#FFFFFF'   },
        ShareFacebook: { active: false, points: 1, image: '', label: 'Like our Facebook Page', itemBackground: '#3b5998', itemColor: '#FFFFFF'  },
        ShareTwitter: { active: false, points: 1, image: '', label: 'Follow us on Twitter', itemBackground: '#1da1f2', itemColor: '#FFFFFF'  },
        ShareLinkedin: { active: false, points: 1, image: '', label: 'Follow us on Linkedin', itemBackground: '#007bb5', itemColor: '#FFFFFF'  },
        SharePinterest: { active: false, points: 1, image: '', label: 'Follow us on Pinterest', itemBackground: '#cb2027', itemColor: '#FFFFFF'  },
      }
    }

    if (this.landing.custom_urls) {
      this.customUrlCounter = Object.keys(this.landing.custom_urls).length + 1
      this.customUrls = this.landing.custom_urls
      let key = 'CustomSocial'+this.customUrlCounter
      this.customUrls = {
        ...this.customUrls,
        [key] : {
            link: '',
            active: false,
            points: 1, 
            label: '', 
            image: '',
            itemBackground: '#3b5998',
            itemColor: '#FFFFFF'
          }
      }
    }

    if (!this.landing.loops) {
      let landingOriginal = JSON.parse(
        JSON.stringify(this.$store.getters['landing/landingOriginal'])
      )
      this.landing.loops = landingOriginal.loops
    }

    if (!this.landing.points.ShareText) {
      this.landing.points.ShareText = { active: false, points: 1, text: 'Check out this cool offer that i just found Follow this link. To check it out', label: 'Share Text', itemBackground: '#548235', itemColor: '#FFFFFF' }
    }
  },

  methods: {
    create() {
    },
    
    async validateStep() {
      let stepValid = false

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.updateOrCreate()
        }
      })

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch(
          this.landing.id ? 'landing/update' : 'landing/create',
          this.landing
        )
        .then((landing) => {
          this.$emit('done', landing)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch(() => {
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
    handleAddCustomSocial() {
      this.customUrlModal = true
    },
    saveCustomSocial() {
      this.isLoadingSave = true
      this.customUrls['CustomSocial'+this.customUrlCounter].active = true
      this.landing.custom_urls = this.customUrls
      this.customUrlCounter++
      let key = 'CustomSocial'+this.customUrlCounter
      this.customUrls = {
        ...this.customUrls,
        [key] : {
            link: '',
            active: false,
            points: 1, 
            label: '', 
            image: '',
            itemBackground: '#3b5998',
            itemColor: '#FFFFFF'
          }
      }
      this.customUrlModal = false
      this.isLoadingSave = false
    },
    removeCustomUrl(key) {
      for(let i = parseInt(key.replace( /^\D+/g, '')); i < this.customUrlCounter; i++) {
          let swap = this.customUrls['CustomSocial'+i]
          this.customUrls['CustomSocial'+ i] = this.customUrls['CustomSocial'+ (i+1)]
          this.customUrls['CustomSocial'+ (i+1)] = swap
      }
      delete this.customUrls['CustomSocial'+this.customUrlCounter]
      this.customUrlCounter--
      delete this.customUrls['CustomSocial'+this.customUrlCounter]
      this.landing.custom_urls = this.customUrls

      let index = 'CustomSocial'+this.customUrlCounter
      this.customUrls = {
        ...this.customUrls,
        [index] : {
            link: '',
            active: false,
            points: 1, 
            label: '',
            image: '',
            itemBackground: '#3b5998',
            itemColor: '#FFFFFF'
          }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0px;
  text-align: center;
}
</style>
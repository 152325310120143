<template>
  <div>
    <div class="list-devices">
      <div
        class="device"
        :class="{ active: selectedDevice === 'desktop' }"
        @click="selectDevice('desktop')"
      >
        <i class="uil uil-desktop-alt"></i>
      </div>
      <div
        class="device"
        :class="{ active: selectedDevice === 'mobile' }"
        @click="selectDevice('mobile')"
      >
        <i class="uil uil-mobile-android-alt"></i>
      </div>
    </div>
    <div class="preview-mockup" :class="selectedDevice">
      <div
        v-if="coupon.type === 'graphic' || coupon.type === 'popup'"
        class="sms-widget"
      >
        <div :style="smsWidgetContainerStyle">
          <div class="widget-close" @click="togglePopup(false)"></div>
          <div
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.logo.Image }"
          >
            <div
              v-if="coupon.sections.logo.Image"
              class="logo-box"
              :style="{ textAlign: coupon.sections.logo.Align }"
            >
              <a :href="coupon.sections.logo.Link">
                <img
                  :src="coupon.sections.logo.Image"
                  alt="Logo"
                  :style="{
                    maxWidth: coupon.sections.logo.MaxWidth + 'px',
                    width: '100%',
                  }"
                />
              </a>
            </div>
          </div>
          <div
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.heroimage.Image }"
          >
            <div
              v-if="coupon.sections.heroimage.Image"
              class="heroimage-box"
              :style="{ textAlign: coupon.sections.heroimage.Align }"
            >
              <img
                :src="coupon.sections.heroimage.Image"
                alt="Logo"
                :style="{
                  maxWidth: coupon.sections.heroimage.MaxWidth + 'px',
                  width: '100%',
                }"
              />
            </div>
          </div>
          <div
            class="widget-block"
            :class="{ 'widget-content': coupon.sections.headline.Content }"
          >
            <div
              v-if="coupon.sections.headline.Content"
              :style="{
                color: coupon.sections.headline.Color,
                fontSize: `${coupon.sections.headline.FontSize}px`,
                textAlign: `${coupon.sections.headline.Align}`,
              }"
            >
              {{ coupon.sections.headline.Content }}
            </div>
          </div>
          <div
            class="widget-block"
            :class="{ 'widget-content': subheadingContent }"
          >
            <div
              v-if="subheadingContent"
              :style="{
                color: coupon.sections.subheading.Color,
                fontSize: `${coupon.sections.subheading.FontSize}px`,
                textAlign: `${coupon.sections.subheading.Align}`,
              }"
            >
              {{ subheadingContent }}
            </div>
          </div>
          <div
            v-if="showInputsSection"
            class="widget-block widget-content mb-2"
          >
            <div v-if="coupon.sections.inputs.ShowName" class="name-input mb-2">
              <input
                type="text"
                :placeholder="coupon.sections.inputs.NamePlaceholder"
                :style="inputsStyle"
                readonly
              />
            </div>

            <div v-if="coupon.sections.inputs.ShowEmail" class="email-input mb-2">
              <input
                type="email"
                :placeholder="coupon.sections.inputs.EmailPlaceholder"
                :style="inputsStyle"
                readonly
              />
            </div>

            <div v-if="coupon.sections.inputs.ShowPhone" class="phone-input" :style="phoneInputsStyle">
              <div class="phone-code">
                <img src="@assets/images/flag-us.png" />
                <span class="code">+1</span>
              </div>
              <input
                type="text"
                :placeholder="coupon.sections.inputs.PhonePlaceholder"
                readonly
              />
            </div>
          </div>
          <div
            class="widget-block widget-content"
          >
            <div style="text-align:center;">
              <a :style="mainButtonStyle">
                <i
                  v-if="coupon.sections.mainbutton.Type === 'icon-left'"
                  class="icon"
                  :class="coupon.sections.mainbutton.Icon"
                ></i>
                {{ coupon.sections.mainbutton.Label }}
                <i
                  v-if="coupon.sections.mainbutton.Type === 'icon-right'"
                  class="icon"
                  :class="coupon.sections.mainbutton.Icon"
                ></i>
              </a>
            </div>
          </div>
          <!-- <div
            v-if="coupon.sections.mobilebutton && selectedDevice === 'mobile'"
            class="widget-block widget-content"
          >
            <div style="text-align:center;">
              <a
                :style="mobileButtonStyle"
                :href="`sms:${coupon.sections.mobilebutton.Number}`"
              >
                <i
                  v-if="coupon.sections.mobilebutton.Type === 'icon-left'"
                  class="icon"
                  :class="coupon.sections.mobilebutton.Icon"
                ></i>
                {{ coupon.sections.mobilebutton.Label }}
                <i
                  v-if="coupon.sections.mobilebutton.Type === 'icon-right'"
                  class="icon"
                  :class="coupon.sections.mobilebutton.Icon"
                ></i>
              </a>
            </div>
          </div> -->
          <div
            class="widget-copyright"
            :style="{ textAlign: `${coupon.sections.legalFooter.Align}` }"
          >
            <a
              :style="{
                color: coupon.sections.legalFooter.Color,
                fontSize: `${coupon.sections.legalFooter.FontSize}px`,
              }"
            >
              {{ coupon.sections.legalFooter[contentAttr] | legal }}
            </a>
          </div>
        </div>
        <div v-if="isShowPopup && user.business.agency.whitelabel.powered_enabled" class="text-right mt-1 powered-by">
          <span class="powered-by-content">powered by <a :href="user.business.agency.whitelabel.powered_url" target="_blank">{{ user.business.agency.whitelabel.powered_name }} </a></span>
        </div>
        <div v-if="coupon.type === 'popup'" class="widget-toggle">
          <div :style="triggerButtonStyle" @click="togglePopup(!isShowPopup)">
            <i
              v-if="coupon.sections.triggerButton.Type === 'icon-left'"
              class="icon"
              :class="coupon.sections.triggerButton.Icon"
            ></i>
            {{ coupon.sections.triggerButton.Label }}
            <i
              v-if="coupon.sections.triggerButton.Type === 'icon-right'"
              class="icon"
              :class="coupon.sections.triggerButton.Icon"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="coupon.type === 'button'">
        <a
          :style="triggerButtonStyle"
          :href="`sms:${user.business.phone_numer}&body=${coupon.keyword}`"
        >
          <i
            v-if="coupon.sections.triggerButton.Type === 'icon-left'"
            class="icon"
            :class="coupon.sections.triggerButton.Icon"
          ></i>
          {{ coupon.sections.triggerButton.Label }}
          <i
            v-if="coupon.sections.triggerButton.Type === 'icon-right'"
            class="icon"
            :class="coupon.sections.triggerButton.Icon"
          ></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coupon: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isShowPopup: true,
      selectedDevice: 'desktop',
    }
  },

  computed: {
    contentAttr() {
      let attr = null

      switch (this.selectedDevice) {
        case 'desktop':
          attr = 'Content'
          break
        case 'mobile':
          attr = 'MobileContent'
          break
      }

      return attr
    },
    showInputsSection() {
      return this.coupon.sections.inputs.ShowName || this.coupon.sections.inputs.ShowEmail || this.coupon.sections.inputs.ShowPhone
    },

    subheadingContent() {
      let content = ''

      switch (this.selectedDevice) {
        case 'desktop':
          content = this.coupon.sections.subheading.Content
          break
        case 'mobile':
          content = this.coupon.sections.subheading.MobileContent
          break
      }
      if (content)
        content = content
          .replace(/<keyword>/, `"${this.coupon.keyword}"`)
          .replace(/<phone number>/, this.user.business.phone_number)
      return content
    },

    user() {
      return this.$store.getters['auth/user']
    },

    smsWidgetContainerStyle() {
      return {
        transform: this.isShowPopup ? 'scaleY(1)' : 'scaleY(0)',
        width: `${this.coupon.sections.styling.MaxWidth}px`,
        background: this.coupon.sections.styling.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.coupon.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
        borderRadius: `${this.coupon.sections.styling.Roudness}px`,
        boxShadow: '0 5px 25px 0 rgba(0,0,0,.20)',
        padding: '20px',
        position: 'absolute',
        bottom: this.user.business.agency.whitelabel.powered_enabled ? '110px' : '80px',
        right: '15px',
        zIndex: 10,
        transition: 'all 0.2s ease',
        transformOrigin: 'bottom right',
      }
    },

    triggerButtonStyle() {
      return {
        background: this.coupon.sections.triggerButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.triggerButton.BorderColor || 'transparent',
        color: this.coupon.sections.triggerButton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.triggerButton.Roudness}px`,
        fontSize: `${this.coupon.sections.triggerButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        zIndex: 5,
      }
    },

    inputsStyle() {
      return {
        backgroundColor: this.coupon.sections.inputs.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.inputs.BorderColor || 'transparent',
        color: this.coupon.sections.inputs.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.inputs.Roudness}px`,
        fontSize: `${this.coupon.sections.inputs.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
        width: '100%',
      }
    },

    phoneInputsStyle() {
      return {
        backgroundColor: this.coupon.sections.inputs.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.inputs.BorderColor || 'transparent',
        color: this.coupon.sections.inputs.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.inputs.Roudness}px`,
        fontSize: `${this.coupon.sections.inputs.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px`,
        width: '100%',
      }
    },

    mainButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mainbutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mainbutton.BorderColor || 'transparent',
        color: this.coupon.sections.mainbutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mainbutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mainbutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },

    mobileButtonStyle() {
      return {
        backgroundColor: this.coupon.sections.mobilebutton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.coupon.sections.mobilebutton.BorderColor || 'transparent',
        color: this.coupon.sections.mobilebutton.Color,
        display: 'inline-block',
        borderRadius: `${this.coupon.sections.mobilebutton.Roudness}px`,
        fontSize: `${this.coupon.sections.mobilebutton.FontSize}px`,
        lineHeight: `38px`,
        padding: `0px 16px`,
      }
    },
  },

  watch: {
    coupon(val) {
      this.isShowPopup = false
      if (val.type === 'graphic') {
        this.isShowPopup = true
      }
    },
  },

  mounted() {
    this.isShowPopup = this.coupon && this.coupon.type === 'graphic'
  },

  methods: {
    togglePopup(isShow) {
      this.isShowPopup = isShow
    },

    selectDevice(device) {
      this.selectedDevice = device
    },
  },
}
</script>

<style lang="scss" scoped>
.sms-widget {
  .powered-by {
    position: absolute;
    bottom: 80px;
    right: 20px;
    font-size: 14px;
    .powered-by-content {
      color: #666;
      padding-right: 10px;
      a {
        color: #666;
        font-weight: bold;
        &:hover {
          text-decoration: underline!important;
        };
      }
    }
  }
  .widget-block {
    margin-bottom: 15px;
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
    > div:hover {
      -webkit-animation: swing 1s ease;
      animation: swing 1s ease;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
    }
  }
  .widget-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512' class='svg-inline--fa fa-times fa-w-11 fa-2x'><path fill='currentColor' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' class=''></path></svg>");
    &:hover {
      opacity: 1;
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    align-items: center;
    display: flex;
    .phone-code {
      position: absolute;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      background-color: inherit;
      border: none;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }

  @-webkit-keyframes swing {
    15% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }
    30% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    50% {
      -webkit-transform: translateX(3px);
      transform: translateX(3px);
    }
    65% {
      -webkit-transform: translateX(-3px);
      transform: translateX(-3px);
    }
    80% {
      -webkit-transform: translateX(2px);
      transform: translateX(2px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes swing {
    15% {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }
    30% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    50% {
      -webkit-transform: translateX(3px);
      transform: translateX(3px);
    }
    65% {
      -webkit-transform: translateX(-3px);
      transform: translateX(-3px);
    }
    80% {
      -webkit-transform: translateX(2px);
      transform: translateX(2px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(_vm.coupon.type === 'graphic' || _vm.coupon.type === 'popup')?_c('div',{staticClass:"sms-widget",style:(_vm.smsWidgetWrapperStyle)},[_c('div',{ref:"graphicContainer",style:(_vm.smsWidgetContainerStyle)},[_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.logo.Image },on:{"click":function($event){return _vm.selectBlock('logo')}}},[(_vm.coupon.sections.logo.Image)?_c('div',{staticClass:"logo-box",style:({ textAlign: _vm.coupon.sections.logo.Align })},[_c('a',{attrs:{"href":_vm.coupon.sections.logo.Link}},[_c('img',{style:({
                maxWidth: _vm.coupon.sections.logo.MaxWidth + 'px',
                width: '100%',
              }),attrs:{"src":_vm.coupon.sections.logo.Image,"alt":"Logo"}})])]):_c('div',{staticClass:"widget-default"},[_c('img',{attrs:{"src":require("@assets/images/widgets/logo-section.svg")}}),_vm._m(0)])]),_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.heroimage.Image },on:{"click":function($event){return _vm.selectBlock('heroimage')}}},[(_vm.coupon.sections.heroimage.Image)?_c('div',{staticClass:"heroimage-box",style:({ textAlign: _vm.coupon.sections.heroimage.Align })},[_c('img',{style:({
              maxWidth: _vm.coupon.sections.heroimage.MaxWidth + 'px',
              width: '100%',
            }),attrs:{"src":_vm.coupon.sections.heroimage.Image,"alt":"Logo"}})]):_c('div',{staticClass:"widget-default"},[_c('img',{attrs:{"src":require("@assets/images/widgets/hero-section.svg")}}),_vm._m(1)])]),_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.coupon.sections.headline.Content },on:{"click":function($event){return _vm.selectBlock('headline')}}},[(_vm.coupon.sections.headline.Content)?_c('div',{style:({
            color: _vm.coupon.sections.headline.Color,
            fontSize: ((_vm.coupon.sections.headline.FontSize) + "px"),
            textAlign: ("" + (_vm.coupon.sections.headline.Align)),
          })},[_vm._v(" "+_vm._s(_vm.coupon.sections.headline.Content)+" ")]):_c('div',{staticClass:"widget-default"},[_c('img',{attrs:{"src":require("@assets/images/widgets/call-text-section.svg")}}),_c('div',{staticClass:"widget-caption"},[_vm._v(" Headline ")])])]),_c('div',{staticClass:"widget-block",class:{ 'widget-content': _vm.subheadingContent },on:{"click":function($event){return _vm.selectBlock('subheading')}}},[(_vm.subheadingContent)?_c('div',{style:({
            color: _vm.coupon.sections.subheading.Color,
            fontSize: ((_vm.coupon.sections.subheading.FontSize) + "px"),
            textAlign: ("" + (_vm.coupon.sections.subheading.Align)),
          })},[_vm._v(" "+_vm._s(_vm.subheadingContent)+" ")]):_c('div',{staticClass:"widget-default"},[_c('img',{attrs:{"src":require("@assets/images/widgets/sec-text-section.svg")}}),_vm._m(2)])]),(_vm.showInputsSection)?_c('div',{staticClass:"widget-block widget-content mb-2",on:{"click":function($event){return _vm.selectBlock('inputs')}}},[(_vm.coupon.sections.inputs.ShowName)?_c('div',{staticClass:"name-input mb-2"},[_c('input',{style:(_vm.inputsStyle),attrs:{"type":"email","placeholder":_vm.coupon.sections.inputs.NamePlaceholder,"readonly":""}})]):_vm._e(),(_vm.coupon.sections.inputs.ShowEmail)?_c('div',{staticClass:"email-input mb-2"},[_c('input',{style:(_vm.inputsStyle),attrs:{"type":"email","placeholder":_vm.coupon.sections.inputs.EmailPlaceholder,"readonly":""}})]):_vm._e(),(_vm.coupon.sections.inputs.ShowPhone)?_c('div',{staticClass:"phone-input",style:(_vm.phoneInputsStyle)},[_vm._m(3),_c('input',{attrs:{"type":"text","placeholder":_vm.coupon.sections.inputs.PhonePlaceholder,"readonly":""}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"widget-block widget-content",on:{"click":function($event){return _vm.selectBlock('mainbutton')}}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('a',{style:(_vm.mainButtonStyle)},[(_vm.coupon.sections.mainbutton.Type === 'icon-left')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.mainbutton.Icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.coupon.sections.mainbutton.Label)+" "),(_vm.coupon.sections.mainbutton.Type === 'icon-right')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.mainbutton.Icon}):_vm._e()])])]),_c('div',{staticClass:"widget-copyright",style:({ textAlign: ("" + (_vm.coupon.sections.legalFooter.Align)) })},[_c('a',{style:({
            color: _vm.coupon.sections.legalFooter.Color,
            fontSize: ((_vm.coupon.sections.legalFooter.FontSize) + "px"),
          })},[_vm._v(" "+_vm._s(_vm._f("legal")(_vm.coupon.sections.legalFooter[_vm.contentAttr]))+" ")])])]),(_vm.user.business.agency.whitelabel.powered_enabled)?_c('div',{staticClass:"text-right mt-1 powered-by"},[_c('span',{staticClass:"powered-by-content"},[_vm._v("powered by "),_c('a',{attrs:{"href":_vm.user.business.agency.whitelabel.powered_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.user.business.agency.whitelabel.powered_name)+" ")])])]):_vm._e(),(_vm.coupon.type === 'popup')?_c('div',{staticClass:"widget-toggle"},[_c('div',{style:(_vm.triggerButtonStyle),on:{"click":function($event){return _vm.selectBlock('triggerButton')}}},[(_vm.coupon.sections.triggerButton.Type === 'icon-left')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.triggerButton.Icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.coupon.sections.triggerButton.Label)+" "),(_vm.coupon.sections.triggerButton.Type === 'icon-right')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.triggerButton.Icon}):_vm._e()])]):_vm._e()]):_vm._e(),(_vm.coupon.type === 'button')?_c('div',{staticStyle:{"margin-top":"100px"}},[_c('a',{style:(_vm.triggerButtonStyle)},[(_vm.coupon.sections.triggerButton.Type === 'icon-left')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.triggerButton.Icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.coupon.sections.triggerButton.Label)+" "),(_vm.coupon.sections.triggerButton.Type === 'icon-right')?_c('i',{staticClass:"icon",class:_vm.coupon.sections.triggerButton.Icon}):_vm._e()])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-caption"},[_vm._v(" Logo "),_c('span',{staticClass:"widget-optional"},[_vm._v("(optional)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-caption"},[_vm._v(" Hero image "),_c('span',{staticClass:"widget-optional"},[_vm._v("(optional)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-caption"},[_vm._v(" Subheading "),_c('span',{staticClass:"widget-optional"},[_vm._v("(optional)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-code"},[_c('img',{attrs:{"src":require("@assets/images/flag-us.png")}}),_c('span',{staticClass:"code"},[_vm._v("+1")])])}]

export { render, staticRenderFns }
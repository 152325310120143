<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="form-group">
        <textarea
          v-model="section.Content"
          class="form-control"
          rows="5"
          placeholder="Tell people how and why they should subscribe to your list."
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
  </div>
</template>

<script>
import NumberInput from './NumberInput'
import ColorInput from './ColorInput'
import AlignInput from './AlignInput'

export default {
  components: {
    NumberInput,
    ColorInput,
    AlignInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<template>
  <div class="landing-section">
    <div v-if="landing">
      <WizardForm
        :landing="landing"
        @landing-changed="landingChanged"
      ></WizardForm>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
  
</template>

<script>
import WizardForm from './WizardForm'
const merge = require('deepmerge')

export default {
  components: {
    WizardForm,
  },

  data() {
    return {
      landing: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getLanding()
  },

  methods: {
    landingChanged(landing) {
      this.landing = landing
    },
    getLanding() {
      this.$store
        .dispatch('landing/find', this.$route.params.id)
        .then((landing) => {
          let landingOriginal = JSON.parse(JSON.stringify(this.$store.getters['landing/landingOriginal']))
          let voteOriginal = JSON.parse(JSON.stringify(this.$store.getters['landing/voteOriginal']))
          this.landing = landing
          this.landing.sections = merge.all([{}, 
            landingOriginal.sections,
            this.landing.sections,
          ])
          if (landing.type === 'vote') {
            this.landing.votes = merge.all([{}, 
              voteOriginal.votes,
              this.landing.votes,
            ])
          }

          this.landing.sections.legalFooter = {
            ...landingOriginal.sections.legalFooter,
            ...this.landing.sections.legalFooter,
            isPolicyLink: this.landing.sections.legalFooter.isPolicyLink === undefined ? true : this.landing.sections.legalFooter.isPolicyLink,
            isServiceLink: this.landing.sections.legalFooter.isServiceLink === undefined ? true : this.landing.sections.legalFooter.isServiceLink,
          }
        })
        .catch(() => {
        })
    },
  },
}
</script>

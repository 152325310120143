<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.LinkColor" label="Link color"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Copyright</label>
        <input
          v-model="section.Content"
          type="text"
          class="form-control"
        />
      </div>
    </div>

    <h5 class="col-md-12 h5">Privacy policy</h5>
    <div class="col-md-12">
      <div class="form-group">
        <label>Label</label>
        <input
          v-model="section.PolicyLabel"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-2">Use Link</label>
        <b-form-checkbox
          v-model="section.isPolicyLink"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div v-if="section.isPolicyLink" class="col-md-12">
      <div class="form-group">
        <label>Link</label>
        <input
          v-model="section.Link"
          type="text"
          class="form-control"
          placeholder="example.com"
        />
      </div>
    </div>

    <h5 class="col-md-12 h5">Terms Of Service</h5>
    <div class="col-md-12">
      <div class="form-group">
        <label>Label</label>
        <input
          v-model="section.ServiceLabel"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-between mb-3">
        <label class="mr-2">Use Link</label>
        <b-form-checkbox
          v-model="section.isServiceLink"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div v-if="section.isServiceLink" class="col-md-12">
      <div class="form-group">
        <label>Link</label>
        <input
          v-model="section.ServiceLink"
          type="text"
          class="form-control"
          placeholder="example.com"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import AlignInput from './AlignInput'

export default {
  components: {
    NumberInput,
    AlignInput,
    ColorInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
